.main-admin {
	padding: 0 var(--padding-sides);
	h1 {
		color: #151515;
	}
	.container-cards-admin {
		padding: 3vh 0;
		.btn-download-excel {
			background: $clrMain;
			color: white;
			border-radius: 5px;
			padding: 1rem 3rem;
			border: none;
			cursor: pointer;
			font-size: $fsSmall;
			display: inline-block;
			margin: 1rem 0;
			font-weight: bold;
			text-align: center;
		}
		.card {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #e5e5e5;
			padding: 2rem;
			margin: 2vmin 0;
			font-size: $fsMedium;
			border-radius: 8px;
			.info-usuario {
				flex: 1;
				color: #393838;
			}
			.numero-documento {
				margin-left: 0.4em;
				color: #707070;
			}
			.fecha-creacion {
				margin-top: 0.4rem;
				font-size: $fsSmall;
				color: #707070;
			}

			.btn-verificar-solicitud {
				background: $clrMain;
				color: white;
				border-radius: 5px;
				padding: 1rem 3rem;
				border: none;
				cursor: pointer;
				font-size: $fsSmall;
			}
			.text-info-solicitud {
				color: rgb(70, 70, 70);
				font-size: $fsSmall;
				text-decoration: underline;
			}
			.btn-reenviar-mail {
				background: $clrMain;
				color: white;
				border-radius: 5px;
				padding: 1rem;
				margin-left: 1rem;
				border: none;
				cursor: pointer;
				font-size: $fsXSmall;
			}
		}
	}
}

.container-cargando {
	width: 100%;
	height: 100%;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	z-index: 1000;
	.ball {
		will-change: transform;
		height: 20px;
		width: 20px;
		background: $clrMain;
		border-radius: 50%;
		animation: grow 1s ease-in-out infinite alternate;
		margin: 0 0.5vw;
		&.ball1 {
			transform-origin: 100% 50%;
		}
		&.ball2 {
			animation-delay: 0.33s;
		}
		&.ball3 {
			animation-delay: 0.66s;
		}
	}

	@keyframes grow {
		to {
			transform: translateX(-50%) scale(0);
		}
	}
}

.txt-cargando {
	color: #303030;
	line-height: 100%;
	margin: 3vh 0 0 0;
	font-size: $fsMedium;
}

.login-form-admin {
	padding: 4vh var(--padding-sides);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.main-solicitud {
	padding: 5vh var(--padding-sides);
	.container-solicitud {
		padding: 0 0 4vh 0;
		h2 {
			font-size: $fsSubHeader;
			padding: 0 0 0.3em 0;
		}
		.campo-informacion {
			padding: 0.3em 0;
			font-size: $fsMedium;
			color: #545454;
			.txt-info-usuario {
				color: #292929;
				font-weight: bold;
				.tipo-diagnostico {
					text-transform: capitalize;
				}
				.btn-descargar-archivos {
					color: $clrMainDark;
					border: none;
					background: none;
					text-decoration: underline;
					font-size: $fsMedium;
					cursor: pointer;
				}
			}
		}
	}
	form {
		.form-group-admin {
			display: flex;
			flex-direction: column;
			label {
				font-size: $fsSmall;
				color: #181818;
				margin: 0 0 0.4em 0;
			}
			textarea {
				resize: none;
				width: 30vmax;
				height: 30vmin;
				padding: 0.5em 0.3em;
				font-size: $fsSmall;
			}
		}
	}
	.botones-solicitud {
		display: flex;
		align-items: center;
		button,
		a {
			padding: 1em 1.3em;
			margin: 2rem 1rem 0 0;
			border: none;
			cursor: pointer;
			border-radius: 6px;
			font-weight: bold;
			display: block;
			font-size: 14px;
			text-decoration: none;
		}
		.btn-aceptar-solicitud {
			background: #47c034;
			color: rgb(31, 31, 31);
		}
		.btn-revision-solicitud {
			background: #e4c435;
			color: rgb(31, 31, 31);
		}
		.btn-rechazar-solicitud {
			background: #c74533;
			color: rgb(255, 244, 244);
		}
		.btn-download {
			background: #e5e5e5;
			font-size: $fsSmall;
		}
	}
}

.form-group-dropdown {
	margin: 1vmax 0 0 0;
	padding-bottom: 0;
}
.dropdown,
.searchbar,
.container-resultados-busqueda {
	font-size: $fsSmall;
	width: 30vmax;
}

.label-ordenar {
	font-size: $fsSmall;
	margin: 3vmin 0 0 0;
}
.opcion-ordenar {
	font-size: $fsSmall;
	display: inline-block;
	margin: 0.3em 1em 0 0;
	padding: 0.5em;
	cursor: pointer;
	border-radius: 8px;
	user-select: none;
}
.opcion-ordenar-active {
	background: $clrMainAccent;
}

.searchbar {
	padding: 0.4em;
	margin: 2vmin 0 0 0;
	border: 1px solid #ccc;
	&:focus {
		outline: 2px solid $clrMain;
		outline-offset: -2px;
	}
}
.container-resultados-busqueda {
	position: absolute;
	background: rgb(231, 231, 231);
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	border: 1px solid #ccc;
	border-top: none;
	border-bottom: none;
	.resultado-busqueda {
		padding: 0.4em;
		cursor: pointer;
		.correo {
			color: rgb(104, 104, 104);
			font-size: $fsXSmall;
		}
		&:hover {
			background: rgb(218, 218, 218);
		}
		border-bottom: 1px solid #ccc;
	}
}

@media screen and (max-width: 1000px) {
	.btn-download-excel {
		width: 100%;
	}
	.dropdown,
	.searchbar {
		width: 100%;
	}
	.opcion-ordenar {
		display: block;
		margin: 1.5vmin 0;
	}
	.container-cards-admin {
		.card {
			flex-direction: column;
			.info-usuario {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				padding: 0 0 3vh 0;
			}
		}
	}
}

.admin-modify {
	padding: 5vh var(--padding-sides);
	form {
		padding: 3vh 0;
	}
	.wrapper-form {
		width: 100%;
		input,
		select {
			width: 100%;
		}
	}
	button {
		background: $clrMain;
		color: white;
		border-radius: 5px;
		padding: 1rem 3rem;
		border: none;
		cursor: pointer;
		font-size: $fsSmall;
		margin: 2vh 0 0 0;
	}
}
