.section-que-es-ruidea {
  padding: 10vh var(--padding-sides);
  position: relative;
  #que-es-ruidea {
    margin: -18vh 0 0 0;
    position: absolute;
  }
  .titulo-info {
    h1 {
      font-size: $fsHeader;
      color: #0a0a0a;
    }
    p {
      font-size: calc(22px + (28 - 22) * ((100vw - 300px) / (1600 - 300)));
      margin: 1rem 0;
      color: rgb(20, 20, 20);
    }
  }
  .item {
    width: 100%;
    padding: 2em;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    min-height: 40vh;
    .container-texto,
    .container-imagen {
      height: 100%;
      flex: 1;
    }
    .container-texto {
      padding: 0 2em;
      display: flex;
      justify-content: center;
      flex-direction: column;
      h2 {
        color: rgb(17, 17, 17);
        font-size: $fsSubHeader;
      }
      .container-textos {
        font-size: calc(22px + (28 - 22) * ((100vw - 300px) / (1600 - 300)));

        p {
          margin: 2vh 0 0 0;
          color: rgb(20, 20, 20);
        }
      }
    }
    .container-imagen {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 60vh;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .item {
    height: fit-content !important;
    flex-direction: column;
    background: rgb(252, 252, 252);
    box-shadow: 0px 2px 4px rgba($color: rgb(212, 212, 212), $alpha: 0.1);
    border-radius: 4px;
    p {
      width: 100%;
      padding: 0 !important;
    }
    .container-imagen {
      img {
        height: 30vh !important;
        margin: 0 auto 5vh auto !important;
        padding: 0 !important;
      }
    }
    .container-texto {
      padding: 0 !important;
      text-align: center;
    }
  }
  .titulo-info {
    text-align: center;
  }
}
