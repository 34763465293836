$clrMain: #41a1ac;
$clrMainDark: #297f89;
$clrMainAccent: #cbf5fa;
$clrFooter: #8fd5dd;

$progressBarSpace: 18vw;

$fsHeader: calc(30px + (44 - 30) * ((100vw - 300px) / (1600 - 300)));
$fsSubHeader: calc(28px + (40 - 28) * ((100vw - 300px) / (1600 - 300)));
$fsMedium: calc(24px + (30 - 24) * ((100vw - 300px) / (1600 - 300)));
$fsSmall: calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));
$fsXSmall: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
