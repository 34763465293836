.main-verificar-mail {
  padding: 10vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 20rem;
    display: block;
  }
  .texto {
    margin: 5vh 0 0 0;
    padding: 0 var(--padding-sides);
  }
  h4 {
    text-align: center;
    font-size: $fsSubHeader;
    color: #141414;
  }
  h1 {
    font-size: calc(30px + (36 - 30) * ((100vw - 300px) / (1600 - 300)));
    margin: 0.5vh 0;
    text-align: center;
    padding: 0 var(--padding-sides);
  }
  a,
  button {
    padding: 1em 1.8em;
    background: $clrMain;
    color: #fff;
    text-decoration: none;
    font-size: $fsSmall;
    margin: 3vh 0;
    border-radius: 4px;
  }
}
