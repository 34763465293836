@import 'variables';
@import 'navbar';
@import 'Login';
@import 'hero';
@import 'info';
@import 'acknowledgements';
@import 'footer';
@import 'dashboard';
@import 'form';
@import 'admin';
@import 'verificar';
@import 'verificarMail';
@import 'changePassword';
@import 'privacyPolicy';

* {
	margin: 0;
	box-sizing: border-box;
	padding: 0;
	font-family: 'Mulish', sans-serif;
}
html {
	scroll-behavior: smooth;
}
:root {
	--padding-sides: 15%;
}

.swal2-icon {
	overflow: hidden;
}
hr {
	width: calc(100vw - var(--padding-sides));
	display: none;
	margin: auto;
}
body {
	overflow-x: hidden;
}

@media screen and (max-width: 1400px) {
	hr {
		display: block;
	}
	:root {
		--padding-sides: 8%;
	}
}
.switch-wrapper {
	position: relative;
	width: 100vw;
}

.switch-wrapper > div {
	width: 100vw;
	position: absolute;
}
