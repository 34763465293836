.change-password-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;

	h1 {
		text-align: center;
	}

	form {
		width: 100vw;
		height: 100vh;
		padding: 0 var(--padding-sides);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.form-group {
			width: 25vw;
			display: flex;
			flex-direction: column;
			padding: 1.5rem 0 0.3rem 0;
			label {
				font-size: $fsSmall;
				margin: 0 0 0.3rem 0;
			}
			input {
				font-size: 24px;
				padding: 0.5em;
				background: #f0f0f0;
				border: none;
				color: #202020;
				position: relative;
				&:focus {
					outline: 3px solid $clrMain;
					outline-offset: -4px;
				}
			}
		}
		button {
			background: $clrMain;
			color: white;
			border-radius: 5px;
			padding: 1rem 2rem;
			width: fit-content;
			border: none;
			font-size: $fsSmall;
			margin: 1.5rem 0 0 0;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 1500px) {
	.form-group {
		width: 70% !important;
	}
}
@media screen and (max-width: 1000px) {
	.form-group {
		width: 100% !important;
	}
}
