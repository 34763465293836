.header-verificar {
	background: #41a1ac;
	width: 100%;
	height: 15vh;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	h1 {
		font-size: $fsHeader;
	}
	.pais {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 4vw;
		font-size: $fsMedium;
	}
	a {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.logo-disfam {
		right: 4vw;
		position: absolute;
		height: 10vh;
		background: #fff;
		border-radius: 50%;
		padding: 1vh;
		transform: translateY(-50%);
	}
}
.pasaporte {
	position: absolute;
	top: 2vh;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: $fsSubHeader;
	color: #161616;
	height: 6vh;
}
.main-verificar {
	padding: 0 6vw;
	min-height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	.flex {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
	}
	.container-logo-ruidea {
		padding: 0 5vmax 0 0;
		.logo-ruidea {
			height: 40vh;
			display: block;
		}
	}
	.container-datos {
		flex: 1;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 3vh 0;

		.texto-main {
			padding: 1vh 0 0 0;
			h1 {
				font-size: calc(40px + (50 - 40) * ((100vw - 300px) / (1600 - 300)));
			}
			h3 {
				font-size: calc(36px + (40 - 36) * ((100vw - 300px) / (1600 - 300)));
				color: #648387;
			}
			h4 {
				font-size: $fsMedium;
				color: #648387;
				margin: 1vh 0 0 0;
			}
		}
		h2 {
			font-size: calc(40px + (50 - 40) * ((100vw - 300px) / (1600 - 300)));
			margin: 1vh 0 0 0;
			color: #161616;
		}
	}
	.desarrollado-por-lecto {
		--clr-font: #303030;
		position: absolute;
		bottom: 2vh;
		color: var(--clr-font);
		text-align: center;
		a {
			color: var(--clr-font);
		}
	}
}

.footer-verificar {
	background: #41a1ac;
	height: 15vh;
	font-size: $fsSmall;
	color: #fff;
	width: 100vw;
}

.error-screen {
	width: 100vw;
	height: 100vh;
	font-size: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: var(--padding-sides);
	position: fixed;
	top: 0;
	background: #fff;
	z-index: 1000;
	h1 {
		text-align: center;
	}
	a {
		background: $clrMain;
		color: #fff;
		padding: 0.6em 1.5em;
		margin: 3vh 0;
		font-size: $fsSmall;
		border-radius: 12px;
		text-decoration: none;
		transition: 0.2s;
		&:hover {
			filter: brightness(1.1);
		}
	}
}

@media screen and (max-width: 1200px) {
	.main-verificar {
		.container-logo-ruidea {
			img {
				height: 30vh !important;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	.main-verificar {
		.pasaporte,
		.desarrollado-por-lecto {
			position: static;
			transform: unset;
			margin: 2vh 0;
			text-align: center;
			height: fit-content;
		}
		.flex {
			flex-direction: column !important;
		}
		.container-logo-ruidea {
			padding: 0;
		}
		.container-datos {
			* {
				text-align: center;
			}
		}
	}

	.footer-verificar {
		height: auto;
	}
}

@media screen and (max-width: 700px) {
	.header-verificar {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column-reverse;
		height: auto;
		padding: 2vh 0;
		* {
			text-align: center;
		}
		.pais,
		.logo-disfam {
			position: static;
			transform: none;
		}
		.logo-disfam {
			margin: 0 0 1vh 0;
		}
	}
}
