.privacy-policy {
	padding: 6vh var(--padding-sides);
	h1 {
		font-size: $fsSubHeader;
	}
	.roman {
		list-style-type: upper-roman;
		list-style-position: inside;
		font-size: $fsSmall;
		& > li {
			margin: 2vh 0;
			.title {
				font-weight: bold;
				display: inline-block;
				line-height: 2;
			}
			p {
				line-height: 1.6;
				margin: 1vh 0;
			}
			ul {
				list-style-type: disc;
				margin: 1vh 0 1vh 2vw;
			}
		}
	}
}
