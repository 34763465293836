main {
  min-height: 86vh;
  display: flex;
  flex-direction: column;
  section {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    .hero {
      height: 100%;
      flex: 1;
      padding: 0 var(--padding-sides);
      display: flex;
      align-items: center;
      .text {
        flex: 0.6;
        h1 {
          padding: 0 3rem 0 0;
          font-size: $fsHeader;
        }
        span {
          color: $clrMain;
        }

        .btn-inscribirme {
          display: block;
          width: fit-content;
          text-decoration: none;
          border: none;
          color: #fff;
          background-color: $clrMain;
          padding: 1em 1.8em;
          border-radius: 4px;
          margin-top: 2rem;
          font-size: $fsSmall;
          cursor: pointer;
        }
      }
      .imagen {
        flex: 0.4;
        img {
          padding: 0 3rem;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .waves {
    * {
      width: 100%;
    }
    position: relative;
    height: auto;
    margin-bottom: -3px;

    .second-wave {
      margin-top: -100%;
    }
  }
}

@media screen and (max-width: 1000px) {
  section {
    display: block !important;
  }
  .hero {
    flex-direction: column-reverse;
  }
  .text,
  .imagen {
    text-align: center;
    flex: 0;
  }
  .imagen {
    padding: 2rem 0;
    img {
      height: 35vh !important;
      width: auto !important;
      padding: 0 !important;
    }
  }
  .text {
    padding: 5vh 0;
    h1 {
      padding: 0 !important;
    }
  }
  .btn-inscribirme {
    margin: 0 auto;
  }
}

@media screen and (max-width: 800px) {
  .waves {
    display: none;
  }
}
