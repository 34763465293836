nav {
  padding: 3vh var(--padding-sides);
  height: 14vh;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
  transition: 0.2s;
  .logo-navbar {
    font-size: 2.5em;
    text-transform: uppercase;
    font-weight: 800;
    text-decoration: none;
    color: #000;
  }
  .links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    p,
    a {
      margin: 0 0 0 3vmin;
      font-size: 1.3em;
      cursor: pointer;
      color: #000;
      text-decoration: none;
    }
    .btn-iniciar-sesion {
      color: $clrMainDark;
      font-weight: 700;
    }
    .btn-cerrar-sesion {
      font-weight: 700;
      color: rgb(231, 41, 41);
    }
  }
}

.nav-scroll {
  height: 10vh;
  box-shadow: 1px 2px 10px rgba(128, 128, 128, 0.6);
}

@media screen and (max-width: 1000px) {
  nav {
    .logo-navbar {
      display: none;
    }
    .links {
      justify-content: space-between !important;

      a {
        font-size: 1.3em !important;
        margin: 0 !important;
        flex: 1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
