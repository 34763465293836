.login-form {
	width: 100vw;
	height: 100vh;
	padding: 0 var(--padding-sides);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.titulo-iniciar-sesion {
		margin: 1rem 0;
		font-size: $fsHeader;
		color: rgb(12, 12, 12);
	}

	.btn-iniciar-sesión {
		background: $clrMain;
		color: white;
		border-radius: 5px;
		padding: 1rem 2rem;
		width: fit-content;
		border: none;
		font-size: $fsSmall;
		margin: 1.5rem 0 0 0;
		cursor: pointer;
	}
}
.form-group {
	width: 25vw;
	display: flex;
	flex-direction: column;
	padding: 0.5rem 0 1rem 0;
	label {
		font-size: $fsSmall;
		margin: 0 0 0.3rem 0;
	}
	input {
		font-size: 24px;
		padding: 0.5em;
		background: #f0f0f0;
		border: none;
		color: #202020;
		position: relative;
		&:focus {
			outline: 3px solid $clrMain;
			outline-offset: -4px;
		}
	}
	.olvide-contraseña {
		margin: 0.25rem 0 0 0;
		cursor: pointer;
	}
}

.forgot-password-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;

	.forgot-password-modal {
		position: absolute;
		width: 35vw;
		background-color: #fff;
		border-radius: 12px;
		padding: 6vh 0 4vh 0;

		@media screen and (max-width: 1400px) {
			width: 65vw;
		}

		@media screen and (max-width: 1000px) {
			width: 90vw;
		}

		.title {
			font-size: $fsSubHeader;
			text-align: center;
		}
		.input-group {
			display: flex;
			flex-direction: column;
			padding: 2rem 6vw;
			.label {
				font-size: $fsSmall;
				margin: 0 0 0.5rem 0;
				display: block;
			}
			.input {
				font-size: 24px;
				padding: 0.5em;
				background: #f0f0f0;
				border: none;
				color: #202020;
				position: relative;
				&:focus {
					outline: 3px solid $clrMain;
					outline-offset: -4px;
				}
			}
			button {
				background: $clrMain;
				color: white;
				border-radius: 5px;
				padding: 1rem 2rem;
				width: fit-content;
				border: none;
				font-size: $fsSmall;
				margin: 1.5rem auto 0 auto;
				cursor: pointer;
			}
			.cancel {
				color: #303030;
				text-decoration: underline;
				text-align: center;
				margin-top: 1rem;
				cursor: pointer;
			}
		}
	}
}

@media screen and (max-width: 1000px) {
	.form-group {
		width: 100%;
	}
}
