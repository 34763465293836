footer {
  background: $clrFooter;
  padding: 1.5rem var(--padding-sides);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  .links {
    padding: 1em;
    p,
    a {
      display: block;
      text-decoration: none;
      margin: 1em 0;
      text-align: center;
      color: #000000;
      cursor: pointer;
      text-decoration: underline;
      font-size: $fsSmall;
    }
  }
  .creado-por-lecto {
    color: #0e0e0e;
    width: 50%;
    text-align: center;
    p {
      font-size: 1.4em;
    }
    .imagenes {
      display: flex;
      justify-content: center;
      align-items: center;
      .imagen-lecto {
        height: 5rem;
      }
      .imagen-disfam {
        height: 10rem;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  footer {
    .creado-por-lecto {
      margin: 0 !important;
      width: 100%;
      .imagenes {
        padding: 5vw 0 0 0 !important;
        flex-direction: column;
        img {
          margin: 0 auto !important;
          height: 200px !important;
        }
      }
    }
  }
}
